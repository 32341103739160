export interface I18nManifest {
  hashMap: Record<string, string>;
}

const isProductionProcess = process.env['NODE_ENV'] === 'production';

const tryParseManifest = (rawManifest: string): I18nManifest => {
  try {
    return JSON.parse(rawManifest);
  } catch (_e) {
    // eslint-disable-next-line no-console
    console.error(
      'Error in parsing i18n-manifest.json, did you missed running i18nManifestInjector script?'
    );
  }

  return { hashMap: {} };
};

const I18N_MANIFEST_RAW: string = '{"hashMap":{"translations":"RbiiJIHgLclXH0ARdwk1Ew%2FV1wG6cbO7lqmeCpTkAGs%3D"}}';

let I18N_MANIFEST: I18nManifest;

export const getI18nManifest = (): I18nManifest => {
  if (!isProductionProcess) {
    throw new Error('getI18nManifest() is available on production only');
  }

  if (!I18N_MANIFEST) {
    I18N_MANIFEST = isProductionProcess
      ? tryParseManifest(I18N_MANIFEST_RAW)
      : { hashMap: {} };
  }

  return I18N_MANIFEST;
};
